
import "./Home.css"

export default function Home() {
    return (
        <div className="filter-background">
            <section className="home-page">
                <h1>Somewhere, something incredible is waiting to be discovered.</h1>
            </section>
        </div>
    )
}